import getDetailStudentApi from './getDetailStudentApi';
import getListEventsApi from './getListEventsApi';
import getListStudentEventsApi from './getListStudentEventsApi';
import getStudentsApi from './getStudentsApi';
import patchCancelEventApi from './patchCancelEventApi';
import patchFeedbackEventApi from './patchFeedbackEventApi';
import patchRescheduleApi from './patchRescheduleApi';
import patchRescheduleLessonApi from './patchRescheduleLessonApi';
import patchRescheduleAvailableApi from './patchRescheduleAvailableApi';
import postRegisterAvailableApi from './postRegisterAvailableApi';
import deleteAvailableApi from './deleteAvailableApi';
import deleteAvailableRuleApi from './deleteAvailableRuleApi';
import listTeachers from './getListTeacherApi';
import getStudentSummary from './getStudentSummaryApi';

export default {
    getStudentsApi,
    getListEventsApi,
    getDetailStudentApi,
    getListStudentEventsApi,
    patchFeedbackEventApi,
    patchCancelEventApi,
    postRegisterAvailableApi,
    patchRescheduleApi,
    patchRescheduleLessonApi,
    patchRescheduleAvailableApi,
    deleteAvailableApi,
    deleteAvailableRuleApi,
    listTeachers,
    getStudentSummary
};
