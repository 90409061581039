const routes = [
  {
    name: 'signin',
    path: '/',
    component: () => import('../pages/Index.vue'),
    children: [],
  },
  {
    path: '/student',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        name: 'subscription-management',
        path: 'subscription',
        component: () => import('../pages/Subscription.vue'),
      },
      {
        name: 'student-my-page',
        path: 'me',
        component: () => import('../pages/student/MyPage.vue'),
      }, 
      {
        name: 'ssr-matching',
        path: 'ssr-matching',
        component: () => import('../pages/student/SsrMatching.vue'),
      },

    ],
  },
  {
    path: '/admin',
    component: () => import('../layouts/AdminLayout.vue'),
    children: [
      {
        name: 'admin-student-management',
        path: 'students',
        component: () => import('../pages/admin/ListStudent.vue'),
      },
      {
        name: 'admin-teacher-management',
        path: 'teachers',
        component: () => import('../pages/admin/ListTeacher.vue'),
      },
      {
        name: 'admin-student-detail',
        path: 'students/:id',
        component: () => import('../pages/admin/DetailStudent.vue'),
      },
      {
        name: 'admin-policy-management',
        path: 'policy',
        component: () => import('../pages/admin/Policy.vue'),
      },
      {
        name: 'admin-teacher-detail',
        path: 'teachers/:id',
        component: () => import('../pages/admin/DetailTeacher.vue'),
      },
    ],
  },
  {
    path: '/teacher',
    component: () => import('../layouts/TeacherLayout.vue'),
    children: [
      {
        name: 'teacher-list-student',
        path: 'students',
        component: () => import('../pages/teacher/ListStudent.vue'),
      },
      {
        name: 'teacher-my-page',
        path: 'me',
        component: () => import('../pages/teacher/MyPage.vue'),
      },
      {
        name: 'teacher-detail-student',
        path: 'students/:id',
        component: () => import('../pages/teacher/DetailStudent.vue'),
      },
    ],
  },

  {
    name: 'teacher-signup',
    path: '/teacher/signup',
    component: () => import('../pages/auth/TeacherSignup.vue'),
  },
  {
    name: 'forgot-password',
    path: '/forgot-password',
    component: () => import('../pages/auth/ForgotPassword.vue'),
  },
  {
    name: 'reset-password',
    path: '/reset-password',
    component: () => import('../pages/auth/ResetPassword.vue'),
  }
]

export default routes
