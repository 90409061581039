export default {
    overlap: 'メッセージエラーの重複',
    canOnlyRegister: '午前00時から午後23時までの間のみお申込みいただけます。毎日',
    availableTimeFixedBlock: 'イベント利用可能時間 固定ブロック30分',
    thanCurrentTime: '値は現在時刻より大きくなければなりません',
    myPage: 'マイページ',
    calendar: 'カレンダー',
    studentStatus: '生徒ステータス',
    teacherFeedback: '教師のフィードバック',
    close: '閉じる',

    event: {
        edit: 'イベントを編集する',
        delete: 'イベントを削除する',
        message: {
            delete: 'イベントは削除されました',
        },
    },
    account: {
        update: {
            success: 'データが更新されました。'
        }
    },
    admin: {
        listStudent: '生徒一覧',
        listTeacher: "教師一覧",
        detailStudent: "生徒詳細",
        detailTeacher: "教師詳細",
        policyManagement: 'ポリシー管理',
        teacherDetail: '先生の詳細',
        scheduleRule: {
            createSuccess: "スケジュールが作成されました。",
            resetSuccess: "スケジュールはリセットされました。",
            message: {
                teacherId: "教師を入力してください。",
                dayOfWeek: "平日を入力してください。",
                startHour: "開始時間を入力してください。",
            }
        },
        updateSchool: {
            schoolId: "スクールを選択してください。",
            success: "スクールが更新されました。"
        },
        policy: {
            success: "ポリシー内容を更新しました。",
            failed: "ポリシー内容の更新が失敗しました。"
        },
        updateEma: {
            success: "入会金額の設定が完了しました。",
            failed: "入会金額の設定が失敗しました。"
        }, 
        setEma: {
            required: "金額を入力してください。",
            min: "金額は少なくとも50 JPY。"
        }
    },
    teacher: {
        listStudent: '生徒一覧',
        detailStudent: "生徒詳細",
        account: {
            message: {
                firstName: "姓前を入力してください。",
                lastName: "名前を入力してください。",
                selfPr: "セルフ PRを入力してください。"
            }
        },
        editEvent: {
            teacherId: "教師を入力してください。",
            startHour: "開始時間を入力してください。"
        }
    }
}