import axios from '../axios'

const signInAPi = async (email, password) => {
  return await axios.post('api/login', {
    email,
    password,
  })
}

export default signInAPi
