<template>
  <v-app style="background: #daffdb">
    <v-main>
      <router-view />
    </v-main>
    <!-- toast -->
    <vtoast ref="vtoast" />
  </v-app>
</template>

<script>
import vtoast from "@/components/common/Snackbar.vue";
export default {
  name: "App",
  components: {
    vtoast,
  },
  data: () => ({
    //
  }),
  mounted() {
    this.$root.vtoast = this.$refs.vtoast
  }
};
</script>

