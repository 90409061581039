import client from '../axios';

const signUpApi = async (email, password, repeatPassword, firstName, lastName, yearOfBirth, gender, phonePrefix, phoneNumber, locale, countryCode, address, isTeacher) =>
    await client.post('api/register', {
        email: email,
        password: password,
        password_confirmation: repeatPassword,
        firstName: firstName,
        lastName: lastName,
        yearOfBirth: yearOfBirth,
        gender: gender,
        phoneNumberPrefix: phonePrefix,
        phoneNumber: phoneNumber,
        locale: locale,
        countryCode: countryCode,
        address: address,
        isTeacher: isTeacher
    });

export default signUpApi 
