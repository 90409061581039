import axios from '../axios'

const getHistory = (userId) =>
    axios.get(`api/admin/payments/history/${userId}`);

const getSummary = (userId) =>
    axios.get(`api/admin/payments/summary/${userId}`);

export default {
    getHistory,
    getSummary,
};
