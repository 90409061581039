import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import messages from './i18n/ja'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'

// import '../dist/output.css'
import dayjs from 'dayjs'
import { createPinia } from 'pinia'
import { markRaw } from 'vue'
import apis from './api'
import './index.css'
import { setupLocale } from './locale'
import CKEditor from '@ckeditor/ckeditor5-vue';
loadFonts()

setupLocale()

const pinia = createPinia()
pinia.use(({ store }) => {
  store.router = markRaw(router)
  store.myApis = markRaw(apis)
})

const i18n = createI18n({
  legacy: false,
  locale: 'ja', // set locale
  fallbackLocale: 'ja', // set fallback locale
  messages: {
    'ja': messages
  }
})

const app = createApp(App)
  .use(router)
  .use(vuetify)
  .use(pinia)
  .use(CKEditor)
  .use(i18n)

app.config.globalProperties.$myApis = apis
app.config.globalProperties.$dayjs = dayjs
app.mount('#app')
