import i18next from "i18next"
import { configure } from "vee-validate"
import { z } from "zod"
import { zodI18nMap } from "zod-i18n-map"
import zodJa from "zod-i18n-map/locales/ja/zod.json"

export function setupLocale() {
  configure({
    validateOnBlur: true,
    validateOnChange: false,
    validateOnInput: false,
    validateOnModelUpdate: false,
  })

  i18next.init({
    lng: "ja",
    resources: {
      ja: {
        zod: zodJa,
      },
    },
  })
  z.setErrorMap(zodI18nMap)
}