import axios from '../axios'
import getDetailTeacherApi from './getDetailTeacherApi'
import getTeacherEventsApi from './getTeacherEventsApi'

const listSchools = () => axios.get('/api/admin/schools')

const listStudents = (data) =>
  axios.get('/api/admin/students', {
    params: data,
  })

const listTeachers = (data) =>
  axios.get('/api/admin/teachers', {
    params: data,
  })

const getStudent = (id) => axios.get(`/api/admin/students/${id}`)

const createStudentSchedule = (id, data) =>
  axios.post(`/api/admin/students/${id}/schedules`, data)

const resetScheduleRule = (id) =>
  axios.post(`/api/admin/students/${id}/reset-schedules`)

const listScheduleRules = (id) =>
  axios.get(`/api/admin/students/${id}/schedules`)

const updateEma = (userId, data) => axios.patch(`api/admin/update-ema/${userId}`, data);
const confirmTeacher = async (id) => await axios.patch(`/api/admin/teachers/${id}/confirm`)

const updateTeacherSchool = async (id, data) => await axios.patch(`/api/admin/teachers/${id}`, data)

const listStudentEvents = async (id, data) =>
  await axios.get(`/api/admin/students/${id}/events`, {
    params: data,
  })

export default {
  listSchools,
  listStudents,
  listTeachers,
  listScheduleRules,
  listStudentEvents,
  getStudent,
  updateEma,
  createStudentSchedule,
  resetScheduleRule,
  confirmTeacher,
  getDetailTeacherApi,
  getTeacherEventsApi,
  updateTeacherSchool
}
