import axios from 'axios'

axios.defaults.withCredentials = true

const client = axios.create({
  baseURL: process.env.VUE_APP_API || 'http://localhost:8000',
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
})

export default client
