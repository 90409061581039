import signInApi from './signIn'
import signUpApi from './signUpApi'
import getCSRFTokenApi from './getCsrfTokenApi'
import forgotPasswordApi from './forgotPasswordApi'
import resetPasswordApi from './resetPasswordApi'
import axios from '../axios'
const getAuthUser = () => axios.get('api/auth/user')

const logout = () => axios.get('api/logout');

export default {
  signInApi,
  signUpApi,
  getCSRFTokenApi,
  forgotPasswordApi,
  resetPasswordApi,
  getAuthUser,
  logout
}
