import { defineStore } from 'pinia'

const LOGGED_USER_ROLE = 'logged_user_role'
export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
    role: localStorage.getItem(LOGGED_USER_ROLE),
  }),
  getters: {
    users: (state) => state.user
  },
  actions: {
    async login(email, password) {
      await this.myApis.auth.getCSRFTokenApi()
      const response = await this.myApis.auth.signInApi(email, password)
      this.user = response.data
      this.setRole(response.data.role)
      if (response.data.role === 'admin') {
        return this.router.push('/admin/students')
      }
      if (response.data.role === 'teacher') {
        return this.router.push('/teacher/me')
      }
      this.router.push('/student/me')
    },
    async getAuthUser() {
      try {
        const response = await this.myApis.auth.getAuthUser()
        this.user = response.data
        this.setRole(response.data.role)
      } catch {
        this.setRole()
        this.user = null
      }
    },
    async logout() {
      await this.myApis.auth.logout()
      this.user = null
      this.setRole()
      this.router.push({ name: "signin" })
    },

    setRole(role) {
      if (role) {
        localStorage.setItem(LOGGED_USER_ROLE, role)
        this.role = role
      } else {
        localStorage.removeItem(LOGGED_USER_ROLE)
        this.role = null
      }
    },
  },
})
