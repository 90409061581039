<template>
  <v-snackbar :color="color" :timeout="timer" v-model="showSnackbar" min-width="100px">
    {{ message }}
  </v-snackbar>
</template>

<script>
export default {
  name: "vtoast",
  data() {
    return {
      showSnackbar: false,
      message: "",
      color: "primary",
      timer: 3000,
    };
  },
  methods: {
    showSuccess(message) {
      this.message = message || 'Success.';
      this.color = "success";
      this.timer = 3000;
      this.showSnackbar = true;
    },
    showError(message) {
      this.message = message || 'Something went wrong.';
      this.color = "red";
      this.timer = 3000;
      this.showSnackbar = true;
    },
  },
};
</script>
<style>
.v-snackbar__content {
  text-align: center;
  color: white;
  font-size: 14px;
}
</style>