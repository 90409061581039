import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { useAuthStore } from '@/stores/auth'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

const studentRoutes = [
  'student-my-page',
  'ticket-management',
  'subscription-management',
]
const adminRoutes = [
  'admin-student-management',
  'admin-teacher-management',
  'admin-student-detail',
  'admin-teacher-detail',
  'admin-policy-management'
]
const teacherRoutes = [
  'teacher-list-student',
  'teacher-my-page',
  'teacher-detail-student'
]
const requireLogoutRoutes = [
  'signin',
  'signup',
  'forgot-password',
  'reset-password',
]

const loginPath = '/'
const homeStudentPath = '/student/me'
const homeAdminPath = '/admin/students'
const homeTeacherPath = '/teacher/me'

router.beforeEach(async (to) => {
  const { user, getAuthUser, role } = useAuthStore()
  if (role && !user) {
    try {
      await getAuthUser()
    } catch {
      return loginPath
    }
  }

  if (requireLogoutRoutes.includes(to.name)) {
    if (role == 'user') return homeStudentPath
    if (role == 'teacher') return homeTeacherPath
    if (role == 'admin') return homeAdminPath
  }

  if (studentRoutes.includes(to.name)) {
    if (role !== 'user') return '/'
  }
  if (adminRoutes.includes(to.name)) {
    if (role !== 'admin') return '/'
  }
  if (teacherRoutes.includes(to.name)) {
    if (role !== 'teacher') return '/'
  }

})

export default router
